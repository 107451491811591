import { actions, mutations } from './types'
import { sdk } from 'Services/shelfNetworkSdk'
import { DEFAULT_DAYS_BACK, DEFAULT_GROUP_PERIOD } from './constants'

export default {

  async [actions.LOAD_LEADS_STATISTICS] (
    { commit }, { from, to, groupByPeriods = DEFAULT_GROUP_PERIOD } = {}
  ) {
    commit(mutations.SET_IS_LOADING, true)

    if (!from) {
      from = new Date()
      from.setDate(from.getDate() - DEFAULT_DAYS_BACK)
      from = from.toISOString()
    }

    if (!to) to = (new Date()).toISOString()

    try {
      const response = await sdk.backOffice.v2.getLeadsStatistics({
        filter: {
          from,
          to,
          group_by_period: groupByPeriods,
        },
        include: [
          'leads_created',
          'leads_created_per_channel',
          'leads_to_on_hold',
          'leads_to_archive',
          'leads_to_assigned',
          'leads_to_assigned_per_broker',
          'leads_to_on_hold_without_broker',
          'leads_from_archive',
          'leads_from_on_hold',
          'identity',
          'identity.location',
          'identity.basics'
        ].join(',')
      })
      commit(mutations.SET_RAW_ANALYTICS_DATA, response)
    } catch (err) {
      console.error('Unable to fetch statistic:', err)
    }
    commit(mutations.SET_IS_LOADING, false)
  },
}
