import { mutations } from './types'
import Vue from 'vue'

export default {
  [mutations.SET_RAW_ANALYTICS_DATA]: (state, raw) => {
    Vue.set(state, 'rawLeadsStatistics', {
      ...raw.data,
      relationships: raw.relationships
    })
  },

  [mutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

}
